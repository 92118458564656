/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, List, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Mechatronické systémy"} description={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap name={"q13ep9ji9wi"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"lgz2ao2y4fp"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"5mjo5v4qc5"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Mechatronické systémy"}>
              </Title>

              <Text className="text-box" content={"Mechatronické systémy jsou vylepšenou variantou mechanických systémů. Tyto prvky lze připojit k elektronické jednotce která je schopna zajišťovat ovládání čipem, poplachem, heslem, mobilním zařízením a mnohem víc. Navíc jsou schopny dávat zpětnou vazbu o stavu vstupních dveří."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Výhody"}>
              </Subtitle>

              <List className="text-box" content={"<li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Možnost napojení na řídící elektroniku</span><br></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Kontrola stavů vstupních dveří</span></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">jednoduchá obsluha i bez použití klíče</span></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Ve spojení s řídící jednotkou smart řešení</span></li>"} subtype={"unordered"}>
              </List>

              <Subtitle className="subtitle-box" content={"Co nabízíme"}>
              </Subtitle>

              <List className="text-box" content={"<li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Poradenskou činnost</span><br></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Montáže včetně řídící jednotky</span></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Napojení na zvonkové systémy</span></li><li><span style=\"background-color: transparent; color: var(--text-color); letter-spacing: var(--text-tracking);\">Servis</span></li>"} subtype={"unordered"}>
              </List>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="pl--40 pr--40" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/19567/d322edfe2e074e8c80cf62cc55b772d1_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/19567/d322edfe2e074e8c80cf62cc55b772d1_s=350x_.png 350w, https://cdn.swbpg.com/t/19567/d322edfe2e074e8c80cf62cc55b772d1_s=660x_.png 660w, https://cdn.swbpg.com/t/19567/d322edfe2e074e8c80cf62cc55b772d1_s=860x_.png 860w, https://cdn.swbpg.com/t/19567/d322edfe2e074e8c80cf62cc55b772d1_s=1400x_.png 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}